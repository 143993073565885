import React from "react"
import Layout from "./../components/layout"
import PageHeader from "../components/pageHeader"
import image25 from "../img/25.jpg"
import MenuComponent from "../components/menuComponent"
import ReservationComponent from "../components/reservationComponent"

const Menu = () => <Layout>
  <PageHeader backgroundImage={image25} title="Our Menu"/>
  <MenuComponent/>
  <ReservationComponent/>
</Layout>

export default Menu
